import { useAuth0 } from '@auth0/auth0-react';
import config from '../config.json';
import { Client } from '../model/Client';
import { Goal } from '../model/Goal';
import { Budget } from '../model/Budget';
import { ExchangeRate } from '../model/ExchangeRate';
import { FinancialElement } from '../model/FinancialElement';
import { Valuation } from '../model/Valuation';
import { OperationDetail } from '../model/OperationDetail';
import { Operation } from '../model/Operation';
import { useCallback } from 'react';
import { getAppVersion, getPlatform } from '../utils/appEnvironment';

export function useAPI() {
    const { getAccessTokenSilently } = useAuth0();

    const runAndRetry = async (fn: () => Promise<any>, options: { retries?: number, delay?: number, backoffFactor?: number } = {}): Promise<any> => {
        const { retries = 2, delay = 500, backoffFactor = 2 } = options;

        const attempt = async (remainingRetries: number, currentDelay: number): Promise<any> => {
            try {
                return await fn();
            } catch (error) {
                if (remainingRetries === 0) throw error;
                await new Promise(resolve => setTimeout(resolve, currentDelay));
                return attempt(remainingRetries - 1, currentDelay * backoffFactor);
            }
        };

        return attempt(retries, delay);
    };

    const executeRequest = useCallback(async (method: string, path: string, queryParams?: Record<string, any>, body?: any): Promise<any> => {
        const accessToken = await getAccessTokenSilently();

        const encodedPath = path
            .split('/')
            .map(segment => encodeURIComponent(segment))
            .join('/');

        const queryString = queryParams
            ? '?' + Object.entries(queryParams)
                .flatMap(([key, value]) =>
                    Array.isArray(value)
                        ? value.map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
                        : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&')
            : '';

        const requestURL = `${config.clients_api_with_authorizer_endpoint.value}${encodedPath}${queryString}`;

        const requestInit = {
            method: method,
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Platform': getPlatform(),
                'App-Version': getAppVersion(),
                'Content-Type': 'application/json'
            }
        };

        // console.log(`${method} ${encodedPath}${queryString}\n${body ? JSON.stringify(body) : ''}`);
        const response = await fetch(requestURL, requestInit);

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        try {
            return await response.json();
        } catch (error) {
            return {};
        }
    }, [getAccessTokenSilently]);

    const get = useCallback(async (path: string, queryParams?: Record<string, any>) => {
        return await runAndRetry(() => executeRequest('GET', path, queryParams, undefined));
    }, [executeRequest]);

    const put = useCallback(async (path: string, body: any) => {
        return await runAndRetry(() => executeRequest('PUT', path, undefined, body));
    }, [executeRequest]);

    const post = useCallback(async (path: string, body: any) => {
        return await executeRequest('POST', path, undefined, body);
    }, [executeRequest]);

    const del = useCallback(async (path: string) => {
        return await runAndRetry(() => executeRequest('DELETE', path, undefined, undefined));
    }, [executeRequest]);

    return {
        getClient: useCallback((clientId: string): Promise<Client> => get(`/clients/${clientId}`), [get]),
        updateClient: useCallback((clientId: string, newClient: Client): Promise<void> => put(`/clients/${clientId}`, newClient), [put]),

        listOperations: useCallback(async (clientId: string, filters: any): Promise<Operation[]> => {
            const operations = [];
            let response = await get(`/clients/${clientId}/operations`, filters);
            operations.push(...response['Items']);

            for (let i = 0; i < 10 && response['LastEvaluatedKey']; i++) {
                const { id, 'timestamp#id': timestamp } = response['LastEvaluatedKey'];
                const filtersWithLastKey = { ...filters, LastEvaluatedKeyId: id, LastEvaluatedKeyTimestampId: timestamp };
                await new Promise(resolve => setTimeout(resolve, 250));
                response = await get(`/clients/${clientId}/operations`, filtersWithLastKey);
                operations.push(...response['Items']);
            }
            return operations;
        }, [get]),
        createOperation: useCallback((clientId: string, newOperation: Operation): Promise<Operation> => post(`/clients/${clientId}/operations`, newOperation), [post]),
        createOperationBatch: useCallback((clientId: string, newOperations: Operation[]): Promise<Operation[]> => post(`/clients/${clientId}/operations-batch`, newOperations), [post]),
        getOperation: useCallback((clientId: string, id: string): Promise<Operation> => get(`/clients/${clientId}/operations/${id}`), [get]),
        updateOperation: useCallback((clientId: string, id: string, newOperation: Operation): Promise<void> => put(`/clients/${clientId}/operations/${id}`, newOperation), [put]),
        deleteOperation: useCallback((clientId: string, id: string): Promise<void> => del(`/clients/${clientId}/operations/${id}`), [del]),
        deleteOperationBatch: useCallback((clientId: string, operationsIds: string[]): Promise<void> => put(`/clients/${clientId}/operations-batch`, operationsIds), [put]),

        listGoals: useCallback((clientId: string): Promise<Goal[]> => get(`/clients/${clientId}/goals`), [get]),
        createGoal: useCallback((clientId: string, newGoal: Goal): Promise<Goal> => post(`/clients/${clientId}/goals`, newGoal), [post]),
        createGoalBatch: useCallback((clientId: string, newGoals: Goal[]): Promise<Goal[]> => post(`/clients/${clientId}/goals-batch-create`, newGoals), [post]),
        getGoal: useCallback((clientId: string, goalId: string): Promise<Goal> => get(`/clients/${clientId}/goals/${goalId}`), [get]),
        updateGoal: useCallback((clientId: string, goalId: string, newGoal: Goal): Promise<void> => put(`/clients/${clientId}/goals/${goalId}`, newGoal), [put]),
        deleteGoal: useCallback((clientId: string, goalId: string): Promise<void> => del(`/clients/${clientId}/goals/${goalId}`), [del]),
        deleteGoalBatch: useCallback((clientId: string, goalIds: string[]): Promise<void> => post(`/clients/${clientId}/goals-batch-delete`, goalIds), [post]),

        listBudgets: useCallback((clientId: string): Promise<Budget[]> => get(`/clients/${clientId}/budgets`), [get]),
        createBudget: useCallback((clientId: string, newBudget: Budget): Promise<Budget> => post(`/clients/${clientId}/budgets`, newBudget), [post]),
        getBudget: useCallback((clientId: string, timestampTo: string): Promise<Budget> => get(`/clients/${clientId}/budgets/${timestampTo}`), [get]),
        updateBudget: useCallback((clientId: string, timestampTo: string, newBudget: Budget): Promise<void> => put(`/clients/${clientId}/budgets/${timestampTo}`, newBudget), [put]),
        deleteBudget: useCallback((clientId: string, timestampTo: string): Promise<void> => del(`/clients/${clientId}/budgets/${timestampTo}`), [del]),

        listExchangeRates: useCallback((exchangeRatesFilters: { codes: string[], from?: string, to?: string }): Promise<ExchangeRate[]> => get(`/exchange_rates`, exchangeRatesFilters), [get]),

        listFinancialElements: useCallback((clientId: string): Promise<FinancialElement[]> => get(`/clients/${clientId}/financial_elements`), [get]),
        createFinancialElement: useCallback((clientId: string, newFinancialElement: FinancialElement): Promise<FinancialElement> => post(`/clients/${clientId}/financial_elements`, newFinancialElement), [post]),
        createFinancialElementBatch: useCallback((clientId: string, newFinancialElements: FinancialElement[]): Promise<FinancialElement[]> => post(`/clients/${clientId}/financial_elements-batch-create`, newFinancialElements), [post]),
        getFinancialElement: useCallback((clientId: string, id: string): Promise<FinancialElement> => get(`/clients/${clientId}/financial_elements/${id}`), [get]),
        updateFinancialElement: useCallback((clientId: string, id: string, newFinancialElement: FinancialElement): Promise<void> => put(`/clients/${clientId}/financial_elements/${id}`, newFinancialElement), [put]),
        updateFinancialElementBatch: useCallback((clientId: string, financialElementsToUpdate: FinancialElement[]): Promise<void> => post(`/clients/${clientId}/financial_elements-batch-update`, financialElementsToUpdate), [post]),
        deleteFinancialElement: useCallback((clientId: string, id: string): Promise<void> => del(`/clients/${clientId}/financial_elements/${id}`), [del]),

        listValuations: useCallback((clientId: string, financialElementFilter?: { financial_element_id?: string }): Promise<Valuation[]> => get(`/clients/${clientId}/valuations`, financialElementFilter), [get]),
        createValuation: useCallback((clientId: string, newValuation: Valuation): Promise<Valuation> => post(`/clients/${clientId}/valuations`, newValuation), [post]),
        getValuation: useCallback((clientId: string, id: string): Promise<Valuation> => get(`/clients/${clientId}/valuations/${id}`), [get]),
        updateValuation: useCallback((clientId: string, id: string, newValuation: Valuation): Promise<void> => put(`/clients/${clientId}/valuations/${id}`, newValuation), [put]),
        deleteValuation: useCallback((clientId: string, id: string): Promise<void> => del(`/clients/${clientId}/valuations/${id}`), [del]),

        listOperationDetails: useCallback((clientId: string): Promise<OperationDetail[]> => get(`/clients/${clientId}/operation_details`), [get]),
        createOperationDetail: useCallback((clientId: string, newOperationDetail: OperationDetail): Promise<OperationDetail> => post(`/clients/${clientId}/operation_details`, newOperationDetail), [post]),
        createOperationDetailBatch: useCallback((clientId: string, newOperationDetails: OperationDetail[]): Promise<OperationDetail[]> => post(`/clients/${clientId}/operation_details-batch-create`, newOperationDetails), [post]),
        getOperationDetail: useCallback((clientId: string, id: string): Promise<OperationDetail> => get(`/clients/${clientId}/operation_details/${id}`), [get]),
        updateOperationDetail: useCallback((clientId: string, id: string, newOperationDetail: OperationDetail): Promise<void> => put(`/clients/${clientId}/operation_details/${id}`, newOperationDetail), [put]),
        updateOperationDetailBatch: useCallback((clientId: string, operationDetailsToUpdate: OperationDetail[]): Promise<void> => post(`/clients/${clientId}/operation_details-batch-update`, operationDetailsToUpdate), [post]),
        deleteOperationDetail: useCallback((clientId: string, id: string): Promise<void> => del(`/clients/${clientId}/operation_details/${id}`), [del]),

        listClientAppointments: useCallback((clientId: string): Promise<{ timestamps: string[], duration_minutes: string }> => get(`/clients/${clientId}/appointments`), [get]),
        createClientAppointment: useCallback((clientId: string, newClientAppointment: {
            timestamp: string,
            client_name: string,
            client_description: string,
            client_phone_number: string,
            timezone: string,
        }): Promise<void> => post(`/clients/${clientId}/appointments`, newClientAppointment), [post]),

        listAvailableTimeSlots: useCallback((): Promise<{ timestamps: string[], duration_minutes: string }> => get(`/available_time_slots`), [get]),
    };
}
