import { Capacitor } from '@capacitor/core';

export function getPlatform(): string {
    try {
        return Capacitor.getPlatform() ?? 'unknown';
    } catch {
        return 'unknown';
    }
}

export function getAppVersion(): string {
    try {
        return process.env.REACT_APP_VERSION ?? 'unknown';
    } catch {
        return 'unknown';
    }
}
